<template>
  <div class="container-fluid">

    <div class="row pb-3">
      <div class="col-md-12">
        <div class="bg-white px-2 py-1">
          <task-tools></task-tools>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12" style="height: 80vh;">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <task-lists :user="user"></task-lists>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import draggable from "vuedraggable";
import VueSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import AssignClinicUser from "../../globals/AssignClinicUser";
import PrioritySelector from "../../globals/PrioritySelector";

import TaskLists from "./partials/TaskLists";
import TaskTools from "./partials/TaskTools";

export default {
  props: ["user"],
  data() {
    return {
      tasks: [],
      clients: [],
      priorities: [
        {
          label: "Priority 1",
          color: "red",
          id: 1
        },
        {
          label: "Priority 2",
          color: "orange",
          id: 2
        },
        {
          label: "Priority 3",
          color: "blue",
          id: 3
        },
        {
          label: "Priority 4",
          color: "green",
          id: 4
        }
      ],
      selectedClient: null,
      clientToAttach: null,
      clientName: null,
      task: null,
      taskInput: "",
      date: null,
      minDate: moment().toISOString(),
      priority: "",
      draggingEnabled: true,
      editingTask: null
    };
  },
  methods: {
    // @deprecated All of the below?
    updateTaskPriority(data) {
      this.$axios
        .post("/tasks/update-priority", {
          task_id: data.model.id,
          priority: data.priority
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchTasks();
        });
    },
    whichColor(n) {
      if (n == "1") {
        return "red";
      } else if (n == "2") {
        return "orange";
      } else if (n == "3") {
        return "blue";
      } else if (n == "4") {
        return "green";
      }
    },
    closeEditTask() {
      this.editingTask = null;
    },
    updateTask() {
      this.$axios
        .post("/tasks/update-detail", {
          task_id: this.editingTask.id,
          name: this.editingTask.name
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.editingTask = null;
          this.fetchTasks();
        });
    },
    editTask(task) {
      this.editingTask = task;
    },
    assignToUser(data) {
      this.$axios
        .post("/tasks/assign-user", {
          task_id: data.modelId,
          assignee_id: data.assignee.id
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchTasks();
        });
    },
    updateOrderOfTasks() {
      this.$axios
        .post("/tasks/update-order", {
          tasks: this.tasks
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    checkInput() {
      if (this.task != null) {
        if (this.task.length < 3) {
          return "is-invalid";
        }
      } else {
        return "";
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    removeDate() {
      this.date = null;
    },
    attachClient(data) {
      this.clientToAttach = data.id;
      this.clientName = data.label;
    },
    removeClient() {
      this.clientToAttach = null;
      this.clientName = null;
    },
    noMatch() {
      console.log("no match");
    },
    fetchTasks() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/tasks/fetch-tasks").then(({ data }) => {
        this.tasks = data.tasks;
        this.colleagues = data.colleagues;
      });
    },
    addNewTask(e) {
      if (this.taskInput.length > 3) {
        this.$axios
          .post("/tasks/add", {
            name: this.taskInput,
            date: this.date,
            client_id: this.clientToAttach,
            priority: this.priority
          })
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.tasks.unshift(data.task);
            this.taskInput = null;
            this.selectedClient = null;
            this.priority = null;
            this.removeClient();
            this.removeDate();
          });
      }
    },
    markAsCompleted(task) {
      this.$axios
        .post("/tasks/completed", {
          task: task
        })
        .then(({ data }) => {
          this.$EventBus.$emit("tasksUpdated");
          this.$EventBus.$emit("alert", data);
        });
    },
    removeTask(task) {
      this.$axios
        .post("/tasks/remove", {
          task: task
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          var index = this.tasks
            .map(function(item) {
              return item.id;
            })
            .indexOf(task);

          this.tasks.splice(index, 1);
        });
    },
    findClient(input) {
      this.$axios.get(process.env.VUE_APP_API_URL + "/tasks/search-clients?q=" + input).then(({ data }) => {
        this.clients = data;
      });
    },
    formatInitials(name) {
      var initials = name.match(/\b\w/g) || [];
      return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format("Do MMM YYYY");
    }
  },
  mounted() {
    this.$EventBus.$on("clientTask", client => {
      $("#modal-tasks").modal("show");
      this.clientToAttach = client.id;
      this.clientName = client.name;
      this.taskInput = "@" + this.clientName + " - ";
    });
  },
  components: {
    draggable,
    "v-select": VueSelect,
    Datepicker,
    AssignClinicUser,
    PrioritySelector,
    TaskLists,
    TaskTools
  }
};
</script>

<style>
.vdp-datepicker input {
  background: #fff !important;
}

.vdp-datepicker input::-webkit-input-placeholder {
  color: rgb(97, 96, 96) !important;
}

.vdp-datepicker__calendar {
  z-index: 9999 !important;
}

.task-input.form-control::-webkit-input-placeholder,
.vs__search::placeholder,
.vs__dropdown-toggle,
.vs__dropdown-menu {
  color: rgb(97, 96, 96);
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}

.color-block {
  display: inline-flex;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.vs__search {
  /* display: none; */
}

.list-hover-shadow:hover {
  /* -webkit-box-shadow: 0 0.5rem 1rem rgba(2, 104, 56, .15) !important;
  box-shadow: 0 0.5rem 1rem rgba(2, 104, 56, .15) !important;
   */
  background-color: rgba(2, 104, 56, 0.15) !important;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}

.assigned-table-row {
  background-color: rgba(2, 104, 56, 0.05);
}
</style>
