<template>
  <div class="col-12 col-md-4 h-100 mb-4">
    <div class="card border-0 bg-light shadow-sm h-100">
      <div class="card-header bg-transparent mb-3 px-2">
        <div class="row">
          <div class="col my-auto">{{ title | capitalizeFirstLetter }}</div>
          <div class="col-auto my-auto text-end">
            <span
              v-tooltip.bottom="'Sort Task List'"
              class="cursor-pointer dropleft d-inline"
              id="sortByDropDown"
              data-toggle="dropdown"
              aria-haspopup="true"
              ario-expanded="false"
            >
              <i class="far fa-sort me-3"></i>
            </span>

            <div class="dropdown-menu" aria-labelledby="sortByDropDown">
              <a class="dropdown-item" @click="sortTaskListBy('name')">Name</a>
              <a class="dropdown-item" @click="sortTaskListBy('priority')">Priority</a>
              <a class="dropdown-item" @click="sortTaskListBy('due_date')">Due date</a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body h-100 pt-0" style="overflow-y: scroll">
        <div v-if="!loaded">
          <div class="row my-3">
            <div class="col text-center">
              <i class="far fa-spin fa-spinner fa-2x text-primary"></i>
            </div>
          </div>
        </div>

        <draggable :id="['list-' + listId]" :list="tasks"
          :group="{ name: 'allTasks', pull: false }"
          :animation="200"
          class="draggable-list">
          <task
            v-for="t in tasks"
            :id="['task-' + t.id]"
            :key="t.id"
            :task="t"
            class="cursor-pointer"
            @selected="expandTask"
            :assigned="true"
          ></task>
        </draggable>
        <!--  -->
      </div>
    </div>
    <expanded-task v-if="expandedTask" :task="expandedTask" @close="expandedTask = null"></expanded-task>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import Task from "./Task";
import ExpandedTask from "./ExpandedTask";

export default {
  props: ["listId", "loaded", "tasks", "title"],
  data() {
    return {
      expandedTask: null,
      loading: true,
      lastSortedBy: null,
      sortDirection: null,
    };
  },
  methods: {
    expandTask(task) {
      this.expandedTask = task;
    },
    sortTaskListBy(type) {
      this.sortDirection = (this.sortDirection && this.lastSortedBy !== type)
        ? "asc" : this.sortDirection === "asc" ? "desc" : "asc";
      this.lastSortedBy = type;

      switch(type) {
        case "name":
          this.tasks.sort((a, b) => a.name.localeCompare(b.name));
          if (this.sortDirection === "desc") {
            this.tasks.reverse();
          }
          break;
        case "priority":
          this.tasks.sort((a, b) => {
            return this.sortHelper(a.priority, b.priority);
          });
          break;
        case "due_date":
          this.tasks.sort((a, b) => {
            return this.sortHelper(a.due_date, b.due_date);
          });
          break;
      }
    },
    sortHelper(a, b) {
      if (this.sortDirection === "desc") {
        return (a === null)-(b === null) || -(a > b) || +(a < b);
      } else {
        return (a === null)-(b === null) || +(a > b) || -(a < b);
      }
    },
  },
  filters: {
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  components: {
    Draggable,
    Task,
    ExpandedTask,
  }
};
</script>

<style>
  .draggable-list {
    min-height: 50px;
  }
</style>