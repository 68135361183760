<template>
  <section>

    <input id="tab-one" type="radio" name="grp" checked="checked"/>
    <label for="tab-one">Add a Task List</label>
    <div>
      <section class="add-task-list">
        <input
          type="text"
          placeholder="Task list title"
          class="form-control col-md-7"
          v-model="taskListTitle"
          v-on:keyup.enter="addTaskList"
          :disabled="taskListDisabled"
        />

        <div class="d-flex align-items-center border-0">
          <button class="form-control btn btn-light task-button me-2"
            @click="addTaskList" :disabled="taskListDisabled">
            <i class="far fa-check me-1"></i>Add Task List
          </button>

          <i class="far fa-info-circle text-primary fa-lg cursor-pointer"
            v-tooltip:auto="'Add a task list such as To-Do, In-Progress, Completed. Click into a task list to add a task to it.'"></i>
        </div>
      </section>
    </div>

    <input id="tab-two" type="radio" name="grp" />
    <label for="tab-two">Add a Task</label>
    <div>
      <section class="d-flex align-items-center add-task">
        <select v-model="taskList" class="form-control w-25 me-2" :disabled="taskDisabled">
          <option value>Select task list</option>
          <option :value="l.id" v-for="l in lists" :key="l.id">
            {{ l.title }}
          </option>
        </select>

        <input
          type="text"
          placeholder="Task title"
          class="form-control col-md-7 task-title"
          v-model="taskTitle"
          v-on:keyup.enter="addTask"
          :disabled="taskDisabled"
        />

        <div class="d-flex align-items-center border-0">
          <button class="form-control btn btn-light task-button me-2" @click="addTask" :disabled="taskDisabled">
            <i class="far fa-check me-1"></i>Add Task
          </button>

          <i class="far fa-info-circle text-primary fa-lg cursor-pointer"
            v-tooltip:auto="'Add a task to a list from here or by clicking into the list below.'"></i>
        </div>
      </section>
    </div>

  </section>
</template>

<script>
export default {
  data() {
    return {
      taskListTitle: "",
      taskList: "",
      taskTitle: "",
      lists: [],
      taskListDisabled: false,
      taskDisabled: false
    };
  },
  methods: {
    addTask() {
      if (!this.taskTitle.length) {
        this.$EventBus.$emit("alert", { message: "Please fill out a task title." })
      } else if (!this.taskList) {
        this.$EventBus.$emit("alert", { message: "Please select a task list." })
      } else {
        this.taskDisabled = true;
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/tasks/add-to-list", {
            name: this.taskTitle,
            task_list_id: this.taskList,
          })
          .then(({ data }) => {
            this.editingList = null;
            this.taskTitle = "";
            this.taskDisabled = false;

            this.$EventBus.$emit("tasksUpdated");
            this.$EventBus.$emit("alert", data);
          });
      }
    },
    fetchTaskLists() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/tasks/fetch-task-lists")
        .then(({ data }) => {
          this.lists = data;
        });
    },
    addTaskList() {
      if (this.taskListTitle.length > 1) {
        this.taskListDisabled = true;
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/tasks/add-task-list", {
            title: this.taskListTitle,
          })
          .then(({ data }) => {
            this.taskListTitle = "";
            this.taskListDisabled = false;

            this.$EventBus.$emit("taskListAdded");
            this.$EventBus.$emit("alert", data);

            this.fetchTaskLists();
          });
      } else {
        this.$EventBus.$emit("alert", {
          message: "Please fill out a task list title.",
        });
      }
    },
  },
  mounted() {
    this.$EventBus.$on("taskListDeleted", e => {
      this.fetchTaskLists();
    });

    this.fetchTaskLists();
  },
};
</script>

<style scoped>
  section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  label {
    background: #eee;
    border: 1px solid #ddd;
    padding: .7em 1em;
    cursor: pointer;
    z-index: 1;
    margin-left: -1px;
  }

  label:first-of-type {
    margin-left: 0;
  }

  div {
    width: 100%;
    margin-top: -1px;
    padding: 1em;
    border: 1px solid #ddd;
    -webkit-order: 1;
    order: 1;
  }

  input[type=radio], div {
    display: none;
  }

  input[type=radio]:checked + label {
    background: #fff;
    border-bottom: 1px solid #fff;
  }

  input[type=radio]:checked + label + div {
    display: block;
  }

  .add-task-list, .add-task {
    display: flex;
    align-items: center;
  }

  .task-title {
    width: 70%;
  }

  .task-button {
    width: 10rem;
  }

  @media only screen and (max-width: 800px) {

  }
</style>