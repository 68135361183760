<template>
  <div>
    <div>
      <div class="dropdown cursor-pointer priority-select">
        <button
          v-if="model.priority != null"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          class="btn btn-outline-primary"
          :key="model.id"
        >
          <i class="far fa-circle me-2" :class="'priority-' + whichColor(model.priority)"></i>
          Select a priority
        </button>

        <button
          v-if="model.priority == null"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          class="dropdown-toggler btn btn-outline-primary"
        >
          <i class="far fa-caret-down me-2"></i>Prioritise task
        </button>

        <div class="dropdown-menu">
          <div
            class="dropdown-item border-bottom border-light py-2"
            v-for="p in priorities"
            @click="selectPriority( model, p.id)"
            :key="p.id"
          >
            <div class="row">
              <div class="col-3 my-auto">
                <i class="far fa-circle fa-2x me-2" :class="'priority-' + p.color" :key="p.id"></i>
              </div>
              <div class="col-9 my-auto">
                <h6 class="mb-0">{{ p.label }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["model"],
  data() {
    return {
      priorities: [
        {
          label: "Priority 1",
          color: "red",
          id: 1
        },
        {
          label: "Priority 2",
          color: "orange",
          id: 2
        },
        {
          label: "Priority 3",
          color: "blue",
          id: 3
        },
        {
          label: "Priority 4",
          color: "green",
          id: 4
        }
      ]
    };
  },
  methods: {
    whichColor(n) {
      if (n == "1") {
        return "red";
      } else if (n == "2") {
        return "orange";
      } else if (n == "3") {
        return "blue";
      } else if (n == "4") {
        return "green";
      }
    },
    selectPriority(model, priority) {
      var data = {
        model: model,
        priority: priority
      };
      this.$emit("selected", data);
    }
  }
};
</script>

<style>
.priority-red {
  color: red;
}
.priority-blue {
  color: blue;
}
.priority-orange {
  color: orange;
}
.priority-green {
  color: green;
}

.priority-select .dropdown-menu {
  max-height: 450px;
  width: 200px;
  overflow-y: auto;
}
</style>