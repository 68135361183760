<template>
  <div class="card border-0 shadow-sm bg-white mb-3" @click="selectTask">
    <div class="card-body rounded-top" :class="'priority-border-' + whichColor(task.priority)">
      <div class="row">
        <div class="col">{{ task.name | capitalizeFirstLetter }}</div>
      </div>
    </div>
    <div class="card-footer bg-white border-top-0 p-2" :class="'priority-border-' + whichColor(task.priority)">

      <div v-if="task.assigned_user" class="flex-row align-items-center justify-content-end">
        <small class="me-1">
          Assigned
          <span v-if="assigned">by</span>
          <span v-else>to</span>
          {{ task.assigned_user.name }}
        </small>
        <img
          :src="task.assigned_user.profile_pic_url"
          class="rounded-circle dropdown-toggle"
          width="32"
          height="32"
          :alt="task.assigned_user.name"
        />
      </div>

      <div class="row">
        <div class="col-auto ms-auto">
          <p v-if="task.due_date"><small><i>{{ task.due_date | formatDate }}</i></small></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["task", "assigned"],
  methods: {
    whichColor(n) {
      if (n == "1") {
        return "red";
      } else if (n == "2") {
        return "orange";
      } else if (n == "3") {
        return "blue";
      } else if (n == "4") {
        return "green";
      }
    },
    selectTask() {
      this.$emit("selected", this.task);
    }
  },
  filters: {
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    formatDate(date) {
      return moment(date).format("L");
    }
  },
};
</script>

<style>
.priority-border-red {
  border-left: solid 5px red;
}
.priority-border-blue {
  border-left: solid 5px blue;
}
.priority-border-orange {
  border-left: solid 5px orange;
}
.priority-border-green {
  border-left: solid 5px green;
}
</style>