<template>
  <div>
    <div class="team-assign dropdown cursor-pointer">
      <span v-if="model.assigned_user"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        @click="openAssigneeMenu(model)">
        <img

          :src="model.assigned_user.profile_pic_url"
          class="rounded-circle dropdown-toggle"
          width="40"
          height="40"
          :alt="model.assigned_user.name"
        />
        {{ model.assigned_user.name }}
      </span>
      <div
        v-else
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        class="btn btn-outline-primary"
        @click="openAssigneeMenu(model)"
      >
        <i class="far fa-user me-2"></i>Select user to assign
      </div>

      <div class="dropdown-menu">
        <div
          class="dropdown-item border-bottom border-light pb-2"
          v-for="u in filterActiveUsers"
          @click="assignToUser(model.id, u)"
          :key="u.id"
        >
          <div class="row">
            <div class="col-auto my-auto">
              <img
                :src="u.profile_pic_url"
                class="rounded-circle cursor-pointer"
                width="40"
                height="40"
                :alt="u.name"
              />
            </div>
            <div class="col-auto my-auto">
              <h6 class="mb-0">
                {{ u.name }}
                <br />
                <small>{{ u.email }}</small>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["model", "user"],
  data() {
    return {
      users: []
    };
  },
  methods: {
    openAssigneeMenu(model) {
      this.showMenu = model;
    },
    fetchColleagues() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/tasks/fetch-assignable-users").then(({ data }) => {
        this.users = data;
      });
    },
    assignToUser(modelId, user) {
      this.model.assigned_user = user;
      this.model.assignee_id = user.id;
      this.showMenu = false;

      this.$emit("assigned", this.model);
    }
  },
  mounted() {
    this.fetchColleagues();
  },
  computed: {
    filterActiveUsers() {
      return this.users.filter(user => {
        return user.status === 'active';
      });
    }
  }
};
</script>

<style>
.team-assign .dropdown-menu {
  max-height: 450px;
  min-width: 400px;
  overflow-y: auto;
}
</style>