<template>
  <div class="h-100">
    <div class="row mt-5 mb-5" v-if="!firstLoad">
      <div class="col-auto mx-auto text-center">
          <div class="row my-3">
            <div class="col text-center">
              <i class="far fa-spin fa-spinner fa-2x text-primary"></i>
            </div>
          </div>
      </div>
    </div>

    <div class="row mt-5 mb-5" v-if="firstLoad && lists.length == 0">
      <div class="col-auto mx-auto text-center">
        <i class="far fa-clipboard-list text-primary fa-7x mx-3 mb-4"></i>
        <i class="far fa-clipboard-list text-primary fa-7x mx-3 mb-4"></i>
        <i class="far fa-clipboard-list text-primary fa-7x mx-3 mb-4"></i>
        <div class="card border-0 shadow-sm bg-light mt-3">
          <div class="card-body">
            <p class="mb-0">Create task lists and then add tasks to them!</p>
            <i class="far fa-arrow-right fa-3x mt-4"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="row d-flex align-items-center mb-3 px-3 pb-2" v-if="lists.length > 0">
      <div class="task-list-toggles">
        <label for="assigned-toggle" class="toggle">
          <span v-if="showAssigned">Show assigned</span>
          <span v-if="!showAssigned">Hide assigned</span>

          <input type="checkbox" id="assigned-toggle" class="checkbox" v-model="showAssignedList">
          <span class="switch"></span>
        </label>

        <label for="completed-toggle" class="toggle">
          <span v-if="showCompleted">Show completed</span>
          <span v-if="!showCompleted">Hide completed</span>

          <input type="checkbox" id="completed-toggle" class="checkbox" v-model="showCompletedList">
          <span class="switch"></span>
        </label>
      </div>

      <div class="task-list-info">
        <i class="far fa-info-circle text-primary fa-lg cursor-pointer"
          v-tooltip:auto="'Scroll across horizontally or use the arrow keys to see more task lists. Tasks can expanded by clicking them.'"></i>
      </div>
    </div>

    <draggable id="taskLists" class="row flex-row flex-nowrap h-100" v-if="lists.length > 0" group="taskLists" handle=".draggable-list" @end="moveTaskList" style="overflow-x: scroll">
      <reusable-list v-show="showAssigned" :listId="'assigned'" :loaded="assignedLoaded" :tasks="assignedTasks" :title="'Assigned Tasks'"></reusable-list>
      <reusable-list v-show="showCompleted" :listId="'completed'" :loaded="completedLoaded" :tasks="completedTasks" :title="'Completed Tasks'"></reusable-list>
      <list :id="['list-' + l.id]" class="draggable-list" v-for="l in lists" :key="l.id" :list="l" :user="user"></list>
    </draggable>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import List from "./task-list-partials/List";
import ReusableList from "./task-list-partials/ReusableList";

export default {
  props: ["user"],
  data() {
    return {
      lists: [],
      assignedTasks: [],
      completedTasks: [],
      assignedLoaded: false,
      completedLoaded: false,
      showAssigned: false,
      showCompleted: false,
      firstLoad: false,
    };
  },
  computed: {
    showAssignedList: {
      get() {
        return this.showAssigned
      },
      set(newValue) {
        if (newValue) {
          this.fetchAssignedTasks();
        }
        this.showAssigned = newValue;
      }
    },
    showCompletedList: {
      get() {
        return this.showCompleted
      },
      set(newValue) {
        if (newValue) {
          this.fetchCompletedTasks();
        }
        this.showCompleted = newValue;
      }
    }
  },
  methods: {
    fetchTaskLists() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/tasks/fetch-task-lists").then(({ data }) => {
        this.lists = data;
        this.firstLoad = true;

        document.addEventListener("keyup", this.columnNavigationListener);
      });
    },
    moveTaskList() {
      let draggableLists = document.querySelectorAll('.draggable-list');
      let orderedLists = [];
      let i = 0;

      draggableLists.forEach((l) => {
        this.lists.forEach((o) => {
          if (o.id == l.id.slice(5)) {
            o.order = i;
            orderedLists.push(o);
          }
        });
        i = i + 1;
      });

      this.$axios
        .post(process.env.VUE_APP_API_URL + "/tasks/update-list-order", {
          task_lists: orderedLists
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    fetchAssignedTasks() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/tasks/fetch-assigned-tasks").then(({ data }) => {
        this.assignedLoaded = true;
        this.assignedTasks = data;
      });
    },
    fetchCompletedTasks() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/tasks/fetch-completed-tasks").then(({ data }) => {
        this.completedLoaded = true;
        this.completedTasks = data;
      });
    },
    columnNavigationListener(e) {
      // Allow for scrolling horizontally using arrow keys instead
      // of having to use the bar at the bottom of the screen

      if (e.keyCode == 37 || e.keyCode == 39) {
        let taskLists = document.getElementById('taskLists');
        let taskListsWidth = parseInt(window.getComputedStyle(taskLists).width, 10)

        let visibleCount  = 0;
        let cardWidth = 0;

        taskLists.children.forEach(child => {
          let childStyle = window.getComputedStyle(child);

          if (childStyle.display !== 'none') {
            visibleCount = visibleCount+1;
            cardWidth = parseInt(childStyle.width, 10);
          }
        });

        if (visibleCount * cardWidth > taskListsWidth) {
          if (e.keyCode == 37) {
            taskLists.scrollLeft -= cardWidth;
          } else {
            taskLists.scrollLeft += cardWidth;
          }
        }
      }
    }
  },
  mounted() {
    this.$EventBus.$on("taskListAdded", e => {
      this.fetchTaskLists();
    });

    this.$EventBus.$on("tasksUpdated", e => {
      this.fetchTaskLists();
      this.fetchAssignedTasks();
      this.fetchCompletedTasks();
    });

    this.$EventBus.$on("taskListDeleted", e => {
      this.fetchTaskLists();
    });

    this.$EventBus.$on("taskAssigned", e => {
      this.fetchAssignedTasks();
    });

    this.$EventBus.$on("taskCompleted", e => {
      this.fetchAssignedTasks();
    });

    this.fetchTaskLists();
  },
  components: {
    Draggable,
    List,
    ReusableList
  }
};
</script>

<style scoped>
  .toggle {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.25);
    margin-left: 5px;
    border-radius: 20px;
    transition: all 0.3s;
  }

  .switch::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius:50%;
    background-color: white;
    top: 1px;
    left: 1px;
    transition: all 0.3s;
  }

  .checkbox:checked + .switch::after {
    left : 20px;
  }

  .checkbox:checked + .switch {
    background-color: #9A99C4;
  }

  .checkbox {
    display : none;
  }

  .task-list-toggles {
    display: flex;
    width: 95%;
  }

  .task-list-info {
    display: flex;
    width: 5%;
    justify-content: flex-end;
  }
</style>