<template>
  <div>
    <div
      class="modal fade"
      id="expandedTaskModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="expandedTaskModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="container">
              <div class="row">
                <div class="d-flex align-items-center col">
                  <h5 class="modal-title" id="expandedTaskModalTitle">
                    {{ task.name | capitalizeFirstLetter }}
                  </h5>
                </div>
                <div class="d-flex align-items-center col-auto ms-auto">
                  <button
                    class="btn btn-outline-danger btn-sm"
                    @click="deleteTask"
                  >
                    <i class="far fa-trash"></i>
                  </button>
                </div>
                <div class="d-flex align-items-center col-auto ms-auto">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal" data-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body" v-if="!task.completed">
            <div class="row mb-3 px-2">
              <div class="col">
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="Task description..."
                  v-model="task.description"
                ></textarea>
              </div>
            </div>
            <hr class="mx-2" />
            <!--  -->
            <div class="row mb-3 px-2">
              <div class="col">
                <h6>Prioritise the task</h6>
                <priority-selector
                  :model="task"
                  @selected="updateTask"
                ></priority-selector>
              </div>

              <div class="col">
                <h6>Assign task to Practitioner</h6>
                <assign-clinic-user
                  :model="task"
                  @assigned="updateTask"
                  :user="user"
                ></assign-clinic-user>
              </div>

              <div class="col float-end">
                <h6>Mark task Completed</h6>
                <button class="btn btn-outline-primary" @click="completeTask">
                  <i class="far fa-check-circle me-2"></i>Complete Task
                </button>
              </div>
            </div>

            <!--  -->

            <div class="row mb-4 px-2">
              <div class="col">
                <label class="mb-1" for>Add a due date to this task</label>
                <flat-pickr
                  v-model="task.due_date"
                  class="form-control form-control-sm bg-white flat-custom-w"
                  placeholder="Select date"
                  :config="dateConfig"
                  name="date"
                ></flat-pickr>
                <small class="form-text"
                  >Will create an event on your calendar</small
                >
              </div>
            </div>

            <!--  -->
            <div class="row">
              <div class="col-auto ms-auto">
                <button
                  class="btn btn-sm btn-outline-primary"
                  @click="updateTask(task)"
                >
                  <i class="far fa-save text-primary me-1"></i>Save
                </button>
              </div>
            </div>

            <!--  -->
          </div>

          <div class="modal-body" v-if="task.completed">
            <div class="row mb-3 px-2">
              <div class="col">
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="Task description..."
                  v-model="task.description"
                  disabled
                ></textarea>
              </div>
            </div>
            <hr class="mx-2" />
            <!--  -->
            <div class="row mb-3 px-2">
              <div class="col">
                <h6 class="mb-2">Task Priority:</h6>
                <div class="flex-row items-center" v-if="task.priority">
                  <i class="far fa-circle fa-2x me-2" :class="'priority-' + task.priority"></i>
                  <span class="align-center">
                    Priority {{ task.priority }}
                  </span>
                </div>
                <div v-else>
                  <span class="flex-row items-center">
                    None
                  </span>
                </div>
              </div>

              <div class="col" v-if="task.assigned_user.name">
                <h6 class="mb-2">Assigned Practitioner:</h6>
                <div class="flex-row">
                    <img
                      :src="task.assigned_user.profile_pic_url"
                      class="rounded-circle dropdown-toggle me-2"
                      width="32"
                      height="32"
                      :alt="task.assigned_user.name"
                    />
                    {{ task.assigned_user.name }}
                </div>
              </div>

              <div class="col" v-if="task.due_date">
                <h6>Task due date:</h6>
                <div>
                  <span class="flex-row items-center">
                    {{ task.due_date | formatDate }}
                  </span>
                </div>
              </div>
            </div>

            <!--  -->
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrioritySelector from "../../../../globals/PrioritySelector";
import AssignClinicUser from "../../../../globals/AssignClinicUser";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  props: ["task", "user"],
  data() {
    return {
      originalDesc: this.task.description,
      dateConfig: {
        altInput: true,
        altFormat: "J M Y",
        dateFormat: "Y-m-d",
      },
    };
  },
  methods: {
    updateTask(t) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/tasks/api/" + this.task.id, t)
        .then(({ data }) => {
          this.$EventBus.$emit("tasksUpdated");
          this.$EventBus.$emit("alert", data);

          if (t.priority) {
            this.task.priority = t.priority;
          }
        });
    },
    deleteTask() {
      if (
        confirm(
          "Are you sure you wish to remove this task?"
        )
      ) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/tasks/api/" + this.task.id)
          .then(({ data }) => {
            this.$EventBus.$emit("tasksUpdated");
            $("#expandedTaskModal").modal("hide");
            this.$EventBus.$emit("alert", data);
          });
      }
    },
    completeTask() {
      var confirmed = confirm(
        "Are you sure you wish to complete this task? The task and associated information will be removed."
      );
      if (confirmed) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/tasks/completed", {
            task: this.task.id,
          })
          .then(({ data }) => {
            this.$EventBus.$emit("tasksUpdated");
            $("#expandedTaskModal").modal("hide");
            this.$EventBus.$emit("alert", data);
          });
      }
    },
  },
  mounted() {
    $("#expandedTaskModal").modal("show");

    $("#expandedTaskModal").on("hide.bs.modal", (e) => {
      this.$emit("close");
    });
  },
  filters: {
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    formatDate(date) {
      return moment(date).format("L");
    }
  },
  watch: {
    task: {
      handler: function (val, oldval) {
        if (val.due_date != oldval.due_date) {
          this.updateTask(val);
        }
      },
      deep: true,
    },
  },
  components: {
    PrioritySelector,
    AssignClinicUser,
    flatPickr,
  },
};
</script>

<style>
  .flex-row {
    display: flex;
  }

  .align-center {
    display: flex;
    align-items: center;
  }

  .priority-1 {
    color: red;
  }

  .priority-2 {
    color: blue;
  }

  .priority-3 {
    color: orange;
  }

  .priority-4 {
    color: green;
  }

  .close {
    border: 0px;
    background-color: #fff;
    font-size: 2rem;
  }
</style>