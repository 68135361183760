<template>
  <div class="col-12 col-md-4 h-100 mb-4">
    <div class="card border-0 bg-light shadow-sm h-100">
      <div class="card-header bg-transparent mb-3 px-2">
        <div class="row">
          <div class="col my-auto">{{ list.title | capitalizeFirstLetter }}</div>
          <div class="col-auto my-auto text-end">
            <span
              v-tooltip.bottom="'Sort Task List'"
              class="cursor-pointer me-2 dropleft d-inline"
              id="sortByDropDown"
              data-toggle="dropdown"
              aria-haspopup="true"
              ario-expanded="false"
            >
              <i class="far fa-sort me-3"></i>
            </span>

            <div class="dropdown-menu" aria-labelledby="sortByDropDown">
              <a class="dropdown-item" @click="sortTaskListBy('name')">Name</a>
              <a class="dropdown-item" @click="sortTaskListBy('priority')">Priority</a>
              <a class="dropdown-item" @click="sortTaskListBy('due_date')">Due date</a>
              <a class="dropdown-item" @click="sortTaskListBy('custom')">Custom</a>
            </div>

            <span
              v-tooltip.bottom="'Remove Task List'"
              class="cursor-pointer"
              @click="deleteTaskList(list.id)"
            >
              <i class="far fa-trash text-danger"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="card-body h-100 pt-0" style="overflow-y: scroll">
        <div v-if="!loaded">
          <div class="row my-3">
            <div class="col text-center">
              <i class="far fa-spin fa-spinner fa-2x text-primary"></i>
            </div>
          </div>
        </div>

        <draggable :id="['list-' + list.id]" :list="tasks"
          group="allTasks"
          :animation="200"
          @change="updateTaskListOrder"
          @end="moveTask"
          class="draggable-list">
          <task
            v-for="t in tasks"
            :id="['task-' + t.id]"
            :key="t.id"
            :task="t"
            class="cursor-pointer"
            :assigned="false"
            @selected="expandTask"
          ></task>
        </draggable>

        <!--  -->
        <div class="row mt-3" v-if="loaded">
          <div class="col">
            <div
              class="card border border-dark bg-light shadow-sm cursor-pointer"
              @click="addTaskToList(list.id)"
              style="border-style: dashed !important"
            >
              <div
                class="card-body"
                :class="editingList == list.id ? 'p-1' : 'p-2'"
              >
                <span v-if="!editingList" class="text-primary">
                  <i class="far fa-plus me-2"></i>Add a task
                </span>
                <input
                  id="task-title-input"
                  type="text"
                  placeholder="Task title"
                  class="form-control bg-transparent"
                  v-if="editingList == list.id"
                  v-model="taskTitle"
                  @keyup.enter="addTask"
                  :disabled="updating"
                  autofocus
                />
                <button
                  v-if="editingList == list.id"
                  class="btn btn-outline-primary btn-sm btn-block mt-2"
                  @click="addTask"
                  :disabled="updating"
                >
                  <i class="far fa-check me-2"></i>Add Task
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <expanded-task
      v-if="expandedTask"
      :task="expandedTask"
      @close="expandedTask = null"
      :user="user"
    ></expanded-task>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import Task from "./Task";
import ExpandedTask from "./ExpandedTask";

export default {
  props: ["list", "user"],
  data() {
    return {
      tasks: [],
      taskTitle: "",
      loaded: false,
      editingList: null,
      expandedTask: null,
      lastSortedBy: null,
      sortDirection: null,
      updating: false,
    };
  },
  methods: {
    addTaskToList(id) {
      this.editingList = id;

      // Autofocus only works once
      setTimeout(() => document.getElementById('task-title-input').focus(), 100);
    },
    deleteTaskList(id) {
      if (
        confirm(
          "Are you sure you wish to remove this task list, all tasks will also be removed?"
        )
      ) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/tasks/delete-task-list/" + id)
          .then(({ data }) => {
            this.$EventBus.$emit("taskListDeleted");
            this.$EventBus.$emit("alert", data);
          });
      }
    },
    sortTaskListBy(type) {
      this.sortDirection = (this.sortDirection && this.lastSortedBy !== type)
        ? "asc" : this.sortDirection === "asc" ? "desc" : "asc";
      this.lastSortedBy = type;

      switch(type) {
        // We sort name differently because it's a locale-
        // specific string that's never null
        case "name":
          this.tasks.sort((a, b) => a.name.localeCompare(b.name));
          if (this.sortDirection === "desc") {
            this.tasks.reverse();
          }
          break;
        case "priority":
          this.tasks.sort((a, b) => {
            return this.sortHelper(a.priority, b.priority);
          });
          break;
        case "due_date":
          this.tasks.sort((a, b) => {
            return this.sortHelper(a.due_date, b.due_date);
          });
          break;
        case "custom":
          this.tasks.sort((a, b) => {
            return this.sortHelper(a.order, b.order);
          });
          break;
      }
    },
    sortHelper(a, b) {
      if (this.sortDirection === "desc") {
        return (a === null)-(b === null) || -(a > b) || +(a < b);
      } else {
        return (a === null)-(b === null) || +(a > b) || -(a < b);
      }
    },
    updateTaskListOrder () {
      let orderedTasks = [];
      let i = 0;

      this.tasks.forEach((task) => {
        task.order = i;
        orderedTasks.push(task);
        i = i + 1;
      });

      this.tasks = orderedTasks;

      this.$axios
        .post(process.env.VUE_APP_API_URL + "/tasks/update-order", {
          tasks: this.tasks
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    addTask() {
      if (this.updating) {
        return;
      }

      this.updating = true;

      this.$axios
        .post(process.env.VUE_APP_API_URL + "/tasks/add-to-list", {
          name: this.taskTitle,
          task_list_id: this.editingList,
        })
        .then(({ data }) => {
          this.editingList = null;
          this.taskTitle = "";
          this.fetchTasks();
          this.$EventBus.$emit("tasksUpdated");
          this.$EventBus.$emit("alert", data);

          this.updating = false;
        });
    },
    expandTask(task) {
      this.expandedTask = task;
    },
    moveTask(e) {
      if (e.from.id !== e.to.id) {
        if (e.to.id === 'list-assigned') {
          this.assignTaskToMe(e.item.id.slice(5));
        } else if (e.to.id === 'list-completed') {
          this.completeTask(e.item.id.slice(5));
        } else {
          this.moveTaskToList(e.item.id.slice(5), e.to.id.slice(5));
        }
      }
    },
    moveTaskToList(taskId, listId) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/tasks/move-to-list", {
          task_id: taskId,
          task_list_id: listId,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    assignTaskToMe(taskId) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/tasks/assign-user", {
          task_id: taskId,
          assignee_id: this.$store.getters['auth/user'].id
        })
        .then(({ data }) => {
          this.$EventBus.$emit("taskAssigned");
          this.fetchTasks();
          this.$EventBus.$emit("alert", data);
        });
    },
    completeTask(taskId) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/tasks/completed", {
          task: taskId,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("taskCompleted");
          this.$EventBus.$emit("alert", data);
        });
    },
    fetchTasks() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/tasks/fetch-tasks/" + this.list.id)
        .then(({ data }) => {
          this.tasks = data;
          this.loaded = true;
        });
    },
  },
  mounted() {
    this.fetchTasks();

    this.$EventBus.$on("tasksUpdated", (e) => {
      this.fetchTasks();
    });
  },
  filters: {
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  components: {
    Draggable,
    Task,
    ExpandedTask,
  },
};
</script>

<style>
  .draggable-list {
    min-height: 50px;
  }
</style>